@import "~@ui/styles/variables";
@import "~@ui/styles/tools";

$image-aspect-ratio: 1.436925;

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.mobile-view {
    @include media-mobile-up {
        display: none;
    }
}

.mobile-header {
    padding: 0 16px;
}

.desktop-view {
    .container {
        display: grid;
        grid-template-areas: 
            'info image'
            'achievements image';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, max-content);
    }

    .form {
        grid-area: info;
        width: 100%;
        align-self: end;
    }

    .achievements {
        grid-area: achievements;
        margin-top: 0;
        align-self: start;
    }

    .image-wrapper {
        grid-area: image;
        width: 100%;

        img {
            top: 50% !important;
            transform: translateY(-50%);
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    // Used for .mobile-button-wrapper with absolute positioning
    position: relative;
}

.form {
    width: 50%;
}

.form__heading {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 32px;

    b, em, strong {
        font-weight: 500;
        color: $color-primary;
    }
}

.form__subsection {
    max-width: 90%;
}

.form__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 32px;
}

.form__input {
    margin-bottom: 32px;
}

.achievements {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
}

.image-wrapper {
    // Must use 'relative' positioning with 'fill' prop of next 'Image' component
    position: relative;

    // height: 580px; // relation: 1.5
    height: 650px;
    width: 870px;

    // Override NextJS img styles to keep correct aspect ratio for image
    img {
        display: grid;
        height: unset !important;
        aspect-ratio: $image-aspect-ratio;
    }
}

.image-wrapper--mobile {
    // Must use 'relative' positioning with 'fill' prop of next 'Image' component
    position: relative;

    // width height relation: 1.234

    @include media-mobile {
        height: 701px;
    }

}

.mobile-button-wrapper {
    display: flex;
    justify-content: center;

    // Need to specify non-static positioning here to correctly handle z-index
    // https: //github.com/vercel/next.js/discussions/30259     
    position: absolute;
    left: calc((100% - 250px) / 2);
    bottom: 15%;
}

@media screen and (min-width: 2000px) {
    .image-wrapper {
        height: 550px;
        width: 825px;
    }
}

@include medium-breakpoint {
    .desktop-view {
        .image-wrapper {
            // height: 450px;
            height: 550px;

            img {
                height: unset !important;
                // max-height: 430px;
                // width: unset !important;

                left: unset !important;
            }
        }

        .achievements {
            margin-top: 24px;
        }
    }

    .form__heading {
        font-size: 56px;
        margin-bottom: 24px;
    }

    .form__description {
        font-size: 18px;
    }

    .form__input {
        margin-bottom: 0;
    }

    .achievements {
        row-gap: 15px;
    }
}

@media screen and (min-width: 1251px) {
    .form__input { 
        .form__input-button {
            display: none;
        }
    }
}


@include small-breakpoint {
    .desktop-view {
        .container {
            height: 460px;
        }

        .image-wrapper {        
            // height: 300px;
            height: 390px;
            // width: 450px;
        }

        .achievements {
            margin-top: 32px;
        }
    }

    .container {
        align-items: center;
    }

    .form {
        padding-top: initial;
    }

    .form__heading {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .form__description {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .form__input {
        &--short {
            display: none;
        }
    }
}

@include media-tablet {
    .desktop-view {
        .container {
            grid-template-areas: 
                'info image'
                'achievements achievements';
            grid-template-columns: 1fr minmax(calc(285px * $image-aspect-ratio), 1fr);
        }

        .form {
            align-self: start;
        }

        .image-wrapper {
            height: 313px;
            margin-bottom: 0;

            img {
                height: 100% !important;
                width: unset !important;
                left: unset !important;
            }
        }

        .achievements {
            margin-top: 32px;

            grid-template-columns: repeat(4, max-content);
            grid-template-rows: max-content;

            justify-content: space-around;
        }
    }
}

@include xsmall-breakpoint {
    .form__heading {
        font-size: 28px;
        margin-bottom: 16px;
    }

    .form__description {
        font-size: 14px;
        margin-bottom: 16px;
    }

    .achievements {
        gap: 10px;
    }
}

@include media-tablet-portrait {
    .desktop-view {
        .container {
            grid-template-columns: minmax(45%, 1fr) minmax(55%, 1fr);
        }

        .form {
            min-height: 262px;
        }

        .achievements {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, max-content);
        }

        .image-wrapper {
            height: 100%;
            width: 100%;

            img {
                height: auto !important;
                width: 100% !important;
                left: 0 !important;
            }
        }
    }
}

@media screen and (max-width: 650px) {

    .image-wrapper {
        height: 224px;
        width: 336px;
    }
}


@include media-mobile {
    .desktop-view {
        display: none;
    }

    .container {
        display: block;
    }

    .form__heading {
        font-size: 36px;
        margin-bottom: 20px;
        width: 80%;
    }

    .achievements {
        grid-template-columns: repeat(2, max-content);
        margin-bottom: 30px;
    }

    .image-wrapper--mobile {
        height: 701px;
    }
}


@media screen and (max-width: 500px) {
    .image-wrapper--mobile {
        height: 617px;
    }
}

@media screen and (max-width: 450px) {
    .image-wrapper--mobile {
        height: 555px;
    }
}

@media screen and (max-width: 400px) {
    .image-wrapper--mobile {
        height: 494px;
    }
}

@media screen and (max-width: 350px) {

    .mobile-view {
        .achievements {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, max-content);
        }
    }

    .image-wrapper--mobile {
        height: 432px;
    }
}

@media screen and (max-width: 300px) {
    .image-wrapper--mobile {
        height: 370px;
    }
}